.showcase {
  width:100%;
  overflow:hidden;
  padding-top:40px;
  padding-bottom:60px;
  padding-left:10px;
  padding-right:10px;
  .record {
    position:relative;
    margin:50px auto;
    display:block;
    background:transparent;
    transition: all 0.25s linear;
    h2 {
      line-height:1;
      .relcode {
        display:block;
        font-size:rem-calc(24);
        font-weight: $subheader-font-weight;
      }
    }
    &:hover {
      transform:translateY(-20px);
    }
    img {
      position:relative;
      transition: all 0.25s linear;
      box-shadow: 2px 20px 10px rgba(0,0,0,0.5);
      &:hover {
        transform:translateY(-10px);
        box-shadow: 10px 35px 20px rgba(0,0,0,0.5);
      }
    }
    figcaption {
      text-align:center;
      margin-top:10px;
    }
  }
  &.one {
    .record {
      max-width:500px;
      max-height:500px;
    }
  }
  &.two {
    h2 {
      font-size:rem-calc(30);
      .relcode {
        font-size:rem-calc(18);
      }
    }
    .record {
      max-width:400px;
      max-height:400px;
    }
  }
  &.three {
    h2 {
      font-size:rem-calc(24);
      .relcode {
        font-size:rem-calc(14);
      }
    }
    .record {
      max-width:300px;
      max-height:300px;
    }
  }
  &.blue {
    background: rgb(125,185,232);
    background: -moz-linear-gradient(top, rgba(125,185,232,1) 0%, rgba(32,124,202,1) 49%, rgba(30,87,153,1) 100%);
    background: -webkit-linear-gradient(top, rgba(125,185,232,1) 0%,rgba(32,124,202,1) 49%,rgba(30,87,153,1) 100%);
    background: linear-gradient(to bottom, rgba(125,185,232,1) 0%,rgba(32,124,202,1) 49%,rgba(30,87,153,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7db9e8', endColorstr='#1e5799',GradientType=0 );
    h1, h2, h3, h4, p, a {
      color:white;
    }
    a:not(.button) {
      border-bottom: 1px dotted white;
      &:hover {
        color:#fa0;
      }
    }
    .button {
      color:white;
      background-color:darken(rgb(30,87,153),5%);
      &:hover {
        background-color:lighten(rgb(30,87,153),5%);
      }
    }

  }
  &.silver {
    background: rgb(242,245,246);
    background: -moz-linear-gradient(top, rgba(242,245,246,1) 0%, rgba(227,234,237,1) 37%, rgba(200,215,220,1) 100%);
    background: -webkit-linear-gradient(top, rgba(242,245,246,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%);
    background: linear-gradient(to bottom, rgba(242,245,246,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f5f6', endColorstr='#c8d7dc',GradientType=0 );
    h1, h2, h3, h4, p, a {
      color:$dark-gray;
    }
    a:not(.button) {
      border-bottom: 1px dotted $dark-gray;
      &:hover {
        color:black;
      }
    }
  }
  &.gold {
    background: rgb(241,231,103);
    background: -moz-linear-gradient(top, rgba(241,231,103,1) 0%, rgba(254,182,69,1) 100%);
    background: -webkit-linear-gradient(top, rgba(241,231,103,1) 0%,rgba(254,182,69,1) 100%);
    background: linear-gradient(to bottom, rgba(241,231,103,1) 0%,rgba(254,182,69,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1e767', endColorstr='#feb645',GradientType=0 );
    h1, h2, h3, h4, p, a {
      color:$black;
    }
    a:not(.button) {
      border-bottom: 1px dotted $light-gray;
      &:hover {
        color:white;
      }
    }

  }
}
