//colors
$white: #ffffff;
$color_shakespeare_approx: #4da7de;
$color_azure_approx: #3e5b98;
$color_punch_approx: #d93e2d;
$color_thunderbird_approx: #c92619;
$color_san_marino_approx: #3371b7;
$color_blaze_orange_approx: #f26109;

//fonts
$font_0: si;
$font_1: si!important;

//urls
$url_0: url(/assets/socicon/si.eot);
$url_1: url(/assets/socicon/si.eot?#iefix);
$url_2: url(/assets/socicon/si.woff);
$url_3: url(/assets/socicon/si.ttf);
$url_4: url(/assets/socicon/si.svg#icomoonregular);
$url_5: url(/assets/socicon/si.svg);

@font-face {
	font-family: $font_0;
	src: $url_0;
	src: $url_1 format('embedded-opentype'), $url_2 format('woff'), $url_3 format('truetype'), $url_4 format('svg');
	font-weight: normal;
	font-style: normal;
}
[class^="soc-"], [class*=" soc-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'si' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.soc {
	overflow: hidden;
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		*display: inline;
		zoom: 1;
		a {
			font-family: $font_1;
			font-style: normal;
			font-weight: 400;
			//Instead of the line below you could use @include box-sizing($bs)
			box-sizing: border-box;
			overflow: hidden;
			text-decoration: none;
			text-align: center;
			display: block;
			position: relative;
			z-index: 1;
			width: 1.4em;
			height: 1.4em;
			line-height: 1.4em;
			font-size: 1.4em;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 4px;
			margin-right: 4px;
			color: $white;
			background-color: none;
		}
	}
}
.soc-twitter {
	background-color: $color_shakespeare_approx;
	&:before {
		content: 'a';
	}
}
.soc-facebook {
	background-color: $color_azure_approx;
	&:before {
		content: 'b';
	}
}
.soc-google {
	background-color: $color_punch_approx;
	&:before {
		content: 'c';
	}
}
.soc-pinterest {
	background-color: $color_thunderbird_approx;
	&:before {
		content: 'd';
	}
}
.soc-linkedin {
	background-color: $color_san_marino_approx;
	&:before {
		content: 'j';
	}
}
.soc-rss {
	background-color: $color_blaze_orange_approx;
	&:before {
		content: ',';
	}
}
.soc-headphones:before {
    content: "1";
}
.soc-cart:before {
    content: "2";
}
.soc-upload:before {
    content: "3";
}
.soc-circle-left:before {
    content: "\2039";
}
.soc-bag:before {
    content: "\43";
}
