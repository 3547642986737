
.bg-{
  &purple {
    background: #5C258D; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #5C258D , #4389A2); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #5C258D , #4389A2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  &blue {
    background: #2c3e50; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #2c3e50 , #3498db); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #2c3e50 , #3498db); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }
  &silver {
    background: #8e9eab; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #8e9eab , #eef2f3); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #8e9eab , #eef2f3);
  }
  &gold {
    background: #F09819; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #F09819 , #EDDE5D); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #F09819 , #EDDE5D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  &green {
    background: #00bf8f; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #00bf8f , #001510); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #00bf8f , #001510); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

}

.bg-blue, .bg-purple, .bg-green, .bg-gold {
  h1, h2, h3, h4, p, a {
    color:white;
  }
  a:not(.button) {
    border-bottom: 1px dotted white;
    &:hover {
      color:#fa0;
    }
  }
  .button {
    color:white;
    background-color:darken(rgb(30,87,153),5%);
    &:hover {
      background-color:lighten(rgb(30,87,153),5%);
    }
  }
}
.bg-silver {
  h1, h2, h3, h4, p, a {
    color:$dark-gray;
  }
  a:not(.button) {
    border-bottom: 1px dotted $dark-gray;
    &:hover {
      color:$dark-gray;
    }
  }
  .button {
    color:$dark-gray;
    background-color:darken(rgb(30,87,153),5%);
    &:hover {
      background-color:lighten(rgb(30,87,153),5%);
    }
  }
}
