//@extend-elements
//original selectors
//.nav-collapse, .nav-collapse ul

nav.stuck {
  width:100%;
  padding:0;
  margin:0;
  position:fixed;
  top:0;
  left:0;
  z-index:1000;
  transition: background-color 0.5s ease;
  background-color:rgba(0,0,0,0.3);
  color:white;
  a {
    color:white;
  }
  &.background--dark {
    // no tint needed on a dark background
    background-color:rgba(0,0,0,0.1);
  }
  &.background--complex {
    //very light tint
    background-color:rgba(0,0,0,0.2);
  }
  &.light, &.background--light {
    //slightly darker tint
    background-color:rgba(0,0,0,0.3);
  }
  .desktoplogo {
    max-width:240px;
    max-height:60px;
    /*adjust the margin according to your logo shape */
    margin-top:10px;
  }
  .mobilelogo {
    max-width:200px;
    max-height:50px;
    /*adjust the margin according to your logo shape */
    margin-top:10px;
  }

  .backbutton {
    padding:10px 0;
    margin:10px 5px 0 0;
    cursor:pointer;
    transition: transform 0.5s ease;
    transform:translateX(5px);
    float:left;
    &.off {
      transform:translateX(-65px);
    }
    img {
      display:block;
      width:32px;
      height:32px;
    }
  }
  .icon {
    line-height:1;
  }
  i{
    font-size:36px;
    color:white;
  }
  .vertical {
    transition: background-color 0.2s;
    li:hover {
      background-color:rgba(0,0,0,0.5)
    }
  }
}

.title-bar {
  .right {
    float:right;
  }
}

footer {
  padding:2em 0;
  &.black {
    background-color:black;
    h2, h3, p {
      color:$light-gray;
    }
  }
  &.grey {
    background-color:$dark-gray;
    h2, h3, p {
      color:white;
    }
  }
  &.white {
    background-color:white;
    h2, h3, p {
      color:$dark-gray;
    }
  }
}
