/*! Lazy Load XT v1.1.0 2016-01-12
 * http://ressio.github.io/lazy-load-xt
 * (C) 2016 RESS.io
 * Licensed under MIT */
.lazy-hidden {
    opacity: 0;
}
.lazy-loaded {
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    opacity: 1;
}

.pad-top {
  padding-top:2em;
  &-big {
    @include breakpoint(medium) {
      padding-top:4em;
    }
    @include breakpoint(medium) {
      padding-top:6em;
    }
    @include breakpoint(large) {
      padding-top:8em;
    }
  }
}
.pad-bot {
  padding-bottom:2em;
  &-big {
    padding-bottom:8em;
  }
}

.relcode {
  text-transform:uppercase;
  letter-spacing:2px;
  font-weight:300;
}
.icon {
  display: inline-block;
  width: rem-calc(2em);
  height: rem-calc(2em);
}
.underline {
  border-bottom: 1px solid $dark-gray;
  margin-bottom: 10px;
}
.simple-list {
  list-style:none;
  margin:0 0 10px 0;
  padding:0;
  li {
    margin:0;
    padding:7px;
  }
}
