.big-image {
  position: relative;
	overflow: hidden;
	cursor: pointer;
  padding:0;
  margin:0;
  height:0;
  @include breakpoint(small) {
    padding-top:100%;
  }
  @include breakpoint(medium) {
    padding-top:80%;
  }
  @include breakpoint(large) {
    padding-top:55%;
  }
  img {
    position: absolute;
    top:-100%; left:0; right: 0; bottom:-100%;
    margin: auto;
    min-height:100%;
    width:auto;
  	opacity: 0.8;
  }
  figcaption {
    padding: 20px;
  	color: #fff;
  	font-size: 1em;
  	-webkit-backface-visibility: hidden;
  	backface-visibility: hidden;
    @include breakpoint(small) {
      max-width:90%;
    }
    @include breakpoint(medium) {
      max-width:70%;
    }
    @include breakpoint(large) {
      max-width:50%;
    }
    &::before, &::after {
      pointer-events: none;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: -webkit-linear-gradient(top, rgba(12, 12, 12,0) 0%, rgba(12, 12, 12,0.8) 75%);
      background: linear-gradient(to bottom, rgba(12, 12, 12,0) 0%, rgba(12, 12, 12,0.8) 75%);
      content: '';
      //opacity: 1;
      -webkit-transform: translate3d(0,40%,0);
      transform: translate3d(0,40%,0);
    }
    &::before, p {
      -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	    transition: opacity 0.35s, transform 0.35s;
    }
    & > a {
      position: absolute;
    	top: 0;
    	left: 0;
    	width: 100%;
    	height: 100%;
      z-index: 10;
    	text-indent: 200%;
    	white-space: nowrap;
    	font-size: 0;
    	opacity: 0;
    }

    h2, p, time {
      color:white;
      margin:0;
    }
    h2, p {
      padding-left:20px;
      padding-right:20px;
      line-height:1.2;
    }
    h2 {
      position: absolute;
      font-size:1.5em;
      font-weight:bold;
    	bottom: 9%;
    	left: 0;
    	width: 100%;
    	-webkit-transition: -webkit-transform 0.35s, color 0.35s;
    	transition: transform 0.35s, color 0.35s;
    	-webkit-transform: translate3d(0,-30%,0);
    	transform: translate3d(0,-30%,0);
    }
    p {
    	position: absolute;
    	bottom: 7%;
    	left: 0;
    	width: 100%;
    	opacity: 0;
    	-webkit-transform: translate3d(0,200px,0);
    	transform: translate3d(0,200px,0);
    }
    &:hover {
      h2 {
        -webkit-transform: translate3d(0,-20%,0) translate3d(0,-30px,0);
  	     transform: translate3d(0,-20%,0) translate3d(0,-30px,0);
      }
      &::before, p {
        opacity: 1;
      }
      p {
  	    -webkit-transform: translate3d(0,0,0);
  	    transform: translate3d(0,0,0);
      }
      &::before {
  	    -webkit-transform: translate3d(0,0,0);
  	    transform: translate3d(0,0,0);

      }
    }
  }
}

.full-screen-image {
  margin:0;
  padding:0;
  position:fixed;
  z-index:-1;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  overflow:hidden;
  img {
      min-height:100%;
      min-width:100%;
  }
}
.content {
  display:block;
  position:relative;
  padding-top:8em;
  min-width:100%;
  min-height:100%;
  z-index:2;
  @include breakpoint(small) {
    padding-top:5em;
    padding-bottom:3em;
  }
  @include breakpoint(medium) {
    padding-top:7em;
    padding-bottom:4em;
  }
  @include breakpoint(large) {
    padding-top:9em;
    padding-bottom:5em;
  }
}
.shadow {
  box-shadow: 2px 20px 10px rgba(0,0,0,0.5);
}
