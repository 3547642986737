/*
 * Keyframes
 */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}



/*
 * CSS Page Transitions
 * Don't forget to add vendor prefixes!
 */
.m-scene {
  .scene_element {
    animation-duration: 0.25s;
    transition-timing-function: ease-in;
    animation-fill-mode: both;

  }

  .scene_element--fadein {
    animation-name: fadeIn;
  }

  .scene_element--fadeinup {
    animation-name: fadeInUp;
  }

  .scene_element--fadeinright {
    animation-name: fadeInRight;
  }
  .scene_element--fadeinleft {
    animation-name: fadeInLeft;
  }

  /** Reverse "exit" animations */
  &.is-exiting {
      .scene_element {
        animation-direction: alternate-reverse;
      }
  }
}
