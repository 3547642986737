// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group forms
////

/// Font color of text inputs.
/// @type Color
$input-color: $black !default;

/// Font family of text inputs.
/// @type Font
$input-font-family: inherit !default;

/// Font size of text inputs.
/// @type Number
$input-font-size: rem-calc(16) !default;

/// Background color of text inputs.
/// @type Color
$input-background: $white !default;

/// Background color of focused of text inputs.
/// @type Color
$input-background-focus: $white !default;

/// Background color of disabled text inputs.
/// @type Color
$input-background-disabled: $light-gray !default;

/// Border around text inputs.
/// @type Border
$input-border: 1px solid $medium-gray !default;

/// Border around focused text inputs.
/// @type Color
$input-border-focus: 1px solid $dark-gray !default;

/// Box shadow inside text inputs when not focused.
/// @type Shadow
$input-shadow: inset 0 1px 2px rgba($black, 0.1) !default;

/// Box shadow outside text inputs when focused.
/// @type Shadow
$input-shadow-focus: 0 0 5px $medium-gray !default;

/// Cursor to use when hovering over a disabled text input.
/// @type Cursor
$input-cursor-disabled: default !default;

/// Properties to transition on text inputs.
/// @type Transition
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out !default;

/// Enables the up/down buttons that Chrome and Firefox add to `<input type='number'>` elements.
/// @type Boolean
$input-number-spinners: true !default;

/// Radius for text inputs.
/// @type Border
$input-radius: $global-radius !default;

@mixin form-element {
  $height: ($input-font-size + ($form-spacing * 1.5) - rem-calc(1));

  display: block;
  box-sizing: border-box;
  width: 100%;
  height: $height;
  padding: $form-spacing / 2;
  border: $input-border;
  margin: 0 0 $form-spacing;

  font-family: $input-font-family;
  font-size: $input-font-size;
  color: $input-color;
  background-color: $input-background;
  box-shadow: $input-shadow;
  border-radius: $input-radius;

  @if has-value($input-transition) {
    transition: $input-transition;
  }

  // Focus state
  &:focus {
    border: $input-border-focus;
    background-color: $input-background-focus;
    outline: none;
    box-shadow: $input-shadow-focus;

    @if has-value($input-transition) {
      transition: $input-transition;
    }
  }
}

@mixin foundation-form-text {
  // Text inputs
  #{text-inputs()},
  textarea {
    @include form-element;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  // Text areas
  textarea {
    max-width: 100%;

    &[rows] {
      height: auto;
    }
  }

  // Disabled/readonly state
  input,
  textarea {
    &:disabled,
    &[readonly] {
      background-color: $input-background-disabled;
      cursor: $input-cursor-disabled;
    }
  }

  // Reset styles on button-like inputs
  [type='submit'],
  [type='button'] {
    border-radius: $global-radius;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  // Reset Normalize setting content-box to search elements
  // scss-lint:disable QualifyingElement
  input[type='search'] {
    box-sizing: border-box;
  }

  // Number input styles
  [type='number'] {
    @if not $input-number-spinners {
      -moz-appearance: textfield;

      [type='number']::-webkit-inner-spin-button,
      [type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
