.top-bar-left, .top-bar-right, figcaption {
  &.background--dark * {
    color:white;
  }
  &.background--complex * {
    color:$dark-gray;
  }
  &.background--light * {
    color:black;
  }
}
