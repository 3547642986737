#player {
  width:100%;
  position:fixed;
  bottom:0;
  left:0;
  background-color:$secondary-color;
  .playerclose {
    position:absolute;
    font-size:24px;
    color:white;
    right:10px;
    top:10px;
  }
}
